import { customScreenUnifacisaApi } from "../../../services/api";

export const getLotesRmMv = async (
  codLote: string,
  apiUser: string,
  apiPassword: string,
  cod_coligada: number
) => {
  const response = await customScreenUnifacisaApi.get("/getBatch", {
    headers: {
      cod_lote: codLote || "",
      cod_coligada: cod_coligada,
      user: apiUser || "",
      password: apiPassword || "",
    },
  });
  return response;
};

export const getSendLotesMvRm = async (
  dtInicio: string,
  dtFim: string,
  apiUser: string,
  apiPassword: string
) => {
  const response = await customScreenUnifacisaApi.get("/makeProcedure", {
    headers: {
      data_ini: dtInicio,
      data_fin: dtFim,
      user: apiUser || "",
      password: apiPassword || "",
    },
  });
  return response;
};
