import { useState } from "react";
import { toast } from "react-toastify";
import {
  getLotesRmMv,
  getSendLotesMvRm,
} from "../../../../Controllers/CustomScreens/Unifacisa/UnifacisaController";

interface MvRmObjectProps {
  dtInicio: string;
  dtFim: string;
}
interface CodeLoteObjectProps {
  cod_lote: string;
  cod_coligada: number;
}
export const useLotesUnifacisa = () => {
  const [codLoteProps, setCodLoteProps] = useState({} as CodeLoteObjectProps);
  const [loadingRmMv, setLoadingRmMv] = useState(false);
  const [loadingMvRm, setLoadingMvRm] = useState(false);

  const [handleResponseRmMv, setHandleResponseRmMv] = useState<string>("");
  const [handleResponseMvRm, setHandleResponseMvRm] = useState<string>("");

  const [mvRmObject, setMvRmObject] = useState<MvRmObjectProps>(
    {} as MvRmObjectProps
  );

  async function handleGetLotesRmMv() {
    setLoadingRmMv(true);
    try {
      const { data } = await getLotesRmMv(
        codLoteProps.cod_lote,
        "PainelUnifacisaUser",
        "Un1f@c1$@Dt!u$eRd2t@1&Gr2",
        codLoteProps.cod_coligada
      );
      if (data.status === 200) {
        toast.success(data.message);
        setLoadingRmMv(false);
        //Limpa o valor do input
        const input = document.getElementById("lotes-rmmv") as HTMLInputElement;
        input.value = "";
      } else {
        toast.error(data.message);
        setLoadingRmMv(false);
      }
      setHandleResponseRmMv(data.message);
    } catch (err) {
      toast.error("Erro ao enviar requisição");
      setHandleResponseRmMv("Erro ao enviar requisição");
      setLoadingRmMv(false);
    }
  }

  async function handleSendLotesMvRm() {
    setLoadingMvRm(true);
    try {
      const { data } = await getSendLotesMvRm(
        mvRmObject.dtInicio,
        mvRmObject.dtFim,
        "PainelUnifacisaUser",
        "Un1f@c1$@Dt!u$eRd2t@1&Gr2"
      );

      if (data.status === 200) {
        toast.success(data.message);
        setLoadingMvRm(false);
        //Limpa o valor do input
        const dtInicio = document.getElementById(
          "dtInicio-mvrm"
        ) as HTMLInputElement;
        dtInicio.value = "";
        //Limpa o valor do input
        const dtFim = document.getElementById("dtFim-mvrm") as HTMLInputElement;
        dtFim.value = "";
      } else {
        toast.error(data.message);
        setLoadingMvRm(false);
      }
      setHandleResponseMvRm(data.message);
    } catch (err) {
      setLoadingMvRm(false);
      toast.error("Erro ao enviar requisição");
      setHandleResponseMvRm("Erro ao enviar requisição");
    }
  }

  return {
    handleGetLotesRmMv,
    setCodLoteProps,
    handleResponseRmMv,
    handleSendLotesMvRm,
    setMvRmObject,
    loadingRmMv,
    loadingMvRm,
    handleResponseMvRm,
  };
};
